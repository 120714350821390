var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": "基本信息",
            },
            on: { "head-cancel": _vm.headCancel },
          }),
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c(
            "div",
            [
              _c("head-layout", { attrs: { "head-title": "培训内容" } }),
              _c(
                "el-table",
                {
                  staticClass: "margin-table",
                  staticStyle: {
                    width: "96%",
                    "margin-bottom": "40px",
                    "margin-top": "10px",
                  },
                  attrs: { data: _vm.tableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "trainingVideo",
                      align: "center",
                      label: "培训视频",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "blue-underline",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowTrainingVideo(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.trainingVideo))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseware",
                      align: "center",
                      label: "课件",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "blue-underline",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowCourseware(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.courseware))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "questions",
                      align: "center",
                      label: "试题",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "blue-underline",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowQuestions(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.questions))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions2,
                  "head-title": "培训人员",
                },
                on: { "head-add-tabs": _vm.headAddThirdPartyPersonnel },
              }),
              _c(
                "el-table",
                {
                  staticClass: "margin-table",
                  staticStyle: {
                    width: "96%",
                    "margin-bottom": "40px",
                    "margin-top": "10px",
                  },
                  attrs: { data: _vm.tableData2, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "index",
                      align: "center",
                      width: "80",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", align: "center", label: "姓名" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "identityCard",
                      align: "center",
                      label: "身份证号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "jobType", align: "center", label: "工种" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "participatingUnits",
                      align: "center",
                      label: "参训单位",
                      formatter: _vm.formatUnitName,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isExam",
                      align: "center",
                      width: "100",
                      label: "是否考试",
                      formatter: _vm.formatIsExam,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isQualified",
                      align: "center",
                      width: "100",
                      label: "是否合格",
                      formatter: _vm.formatIsExam,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fraction",
                      align: "center",
                      width: "100",
                      label: "分数",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: "操作",
                      width: "120",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑\n              ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions3,
              "head-title": "现场照片",
            },
            on: { "head-update-tabs": _vm.headAddImages },
          }),
          _c(
            "div",
            { staticClass: "pictureBox" },
            [
              _c("avue-form", {
                attrs: {
                  option: _vm.option,
                  "upload-preview": _vm.rowView,
                  "upload-after": _vm.uploadAfter,
                  "upload-delete": _vm.uploadDelete,
                },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("addThirduser", {
        ref: "addThirduser",
        on: { callback: _vm.getPrjthirduserPage },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          staticClass: "paperDialog",
          attrs: {
            title: "培训人员编辑",
            modal: true,
            "modal-append-to-body": false,
            visible: _vm.updatePersonnelDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updatePersonnelDialog = $event
            },
            close: _vm.closeUpdatePersonnelDialog,
          },
        },
        [
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("form-layout", {
                    ref: "personnelFormLayout",
                    attrs: {
                      column: _vm.personnelFormColumn,
                      dataForm: _vm.personnelForm,
                    },
                    on: {
                      "update:dataForm": function ($event) {
                        _vm.personnelForm = $event
                      },
                      "update:data-form": function ($event) {
                        _vm.personnelForm = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialogBtn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "medium" },
                  on: { click: _vm.closeUpdatePersonnelDialog },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("video", {
        ref: "video",
        staticStyle: { display: "none" },
        attrs: { id: "myVideo", src: _vm.videoUrl, crossOrigin: "anonymous" },
      }),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: { id: "myCanvas", crossOrigin: "anonymous" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }